<template>
  <pessoa-box class="no-border box-actions-concluido box-historico overflow-unset">
    <div class="c-center">
      <h5>Concluído</h5>
    </div>

    <ul class="nav-aba">
      <li v-for="(m, i) in menu"><a @click="changeTab(m)" :class="{ativo: m.name === active, hasData: hasData(m) || m.name === 'tudo'}">{{ m.label }}<span class="count" v-if="hasData(m)">{{hasData(m)}}</span></a></li>
    </ul>

    <div class="cont-bodyAll pos-rlt">
      <div class="itemAba ativo">
        <div class="timelineBar"></div>
        <ul class="line-andamento">
          <!--<li class="with-box">
            <i class="box-icon material-icons">attach_file</i>
            ...<br>
            ...<br>
            ...<br>
            ...<br>
          </li>

          <li class="with-box note">
            <i class="box-icon material-icons">description</i>
            <h4><span>Pessoa criada</span></h4>
            <p>Hoje às 05:42 <span>Tiago (Aplicativo web)</span></p>
          </li>
          <li class="no-icon">
            <h4>Negócio criado em: <span>Negócio Maria</span> <small>R$ 0</small></h4>
            <p>Hoje às 05:42 <span>Tiago</span></p>
          </li>-->

          <component v-for="(h, index) in historico" :key="index" :h="h" v-bind:is="getTab(h)"
                     @remove="remove"
                     :is-removed="h.action.indexOf('delete') !== -1"
          />
          <div v-if="loading">
            <u-fake-loader width="80%" />
            <u-fake-loader width="20%" />
          </div>

          <li v-if="!loading && (active === 'tudo' || active === 'alteracoes')" class="no-icon flow-info">
            <h4><span>Processo criado</span></h4>
            <p class="firstLetterUppercase">{{ processo.createdAt.date|formatDateDistance }} em {{ processo.createdAt.date|formatDate }} <span class="withSeparator">{{
                processo.createdByName || processo.createdByUser
              }} <!--(Aplicativo web)--></span></p>
          </li><!---->
        </ul>
      </div>
    </div>
  </pessoa-box>
</template>

<script>
import Mixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {historico, showNotaLogByReferId} from "@/domain/processos/services"
import FlowSimple from '@/components/pessoa/components/include/historico/Simple'
import FlowModify from '@/components/pessoa/components/include/historico/Modify'
import Nota from '@/components/pessoa/components/include/historico/Nota'
import File from '@/components/pessoa/components/include/historico/File'
import Vue from "vue"
import HistoryState from "@/domain/pessoa/helpers/HistoryState" // @TODO: Analisar, está usando da pessoa
import {UFakeLoader} from "uloc-vue"

Vue.component('simple', FlowSimple)
Vue.component('modify', FlowModify)
Vue.component('note', Nota)
Vue.component('file', File)

export default {
  name: "ProcessoHistorico",
  mixins: [Mixin],
  components: {
    PessoaBox, UFakeLoader
  },
  data() {
    return {
      loading: true,
      active: 'tudo',
      historico: null,
      counts: null
    }
  },
  mounted() {
    this.load(this.active)
    HistoryState.listen = this.loadHistoryByRefer
  },
  beforeDestroy() {
    HistoryState.listen = null
  },
  computed: {
    menu() {
      return [
        {
          label: 'Tudo',
          name: 'tudo'
        },
        /*{
          label: 'Tarefas',
          name: 'atividades'
        },*/
        {
          label: 'Observações',
          name: 'observacoes'
        },
        {
          label: 'Contatos',
          name: 'comunicacao'
        },
        {
          label: 'Arquivos',
          name: 'arquivos'
        },
        {
          label: 'Negócios',
          name: 'negocios'
        },
        {
          label: 'Financeiro',
          name: 'financeiro'
        },
        {
          label: 'Alterações',
          name: 'alteracoes'
        }
      ]
    }
  },
  watch: {
    active (v) {
      this.load(v)
    }
  },
  methods: {
    changeTab(m) {
      if (!this.hasData(m) && m.name !== 'tudo') return
      this.active = m.name
    },
    load(t) {
      this.loading = true
      this.historico = null
      historico(this.processo, t)
          .then(({data}) => {
            console.log(data)
            this.historico = data.historico
            this.counts = data.counts
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    getTab(h) {
      let object = h.action.split('.')
      object = object[0]
      switch (object) {
        case 'note':
        case 'comunication':
          return 'note'
        case 'modify':
          return 'modify'
        case 'file':
          return 'file'
        default:
          return 'simple'
      }
    },
    loadHistoryByRefer(referId) {
      console.log('Loading history by refer id ', referId)
      showNotaLogByReferId(this.processo, referId)
          .then(({data}) => {
            this.historico.unshift(data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    hasData (m) {
      return this.counts && typeof this.counts[m.name] !== 'undefined' && Number(this.counts[m.name]) > 0 ? Number(this.counts[m.name]) : null
    },
    remove(h, index = 0) {
      console.log('Remove', h, this.historico)
      const indexHistorico = this.historico.indexOf(this.historico.find(hi => hi.id === h.id))
      const {list = []} = this.historico[indexHistorico].newObject
      const length = list?.length || 0
      if  (length > 1) {
        list.splice(index, 1)
      } else {
        this.historico.splice(indexHistorico, 1)
      }
    }
  }
}
</script>
