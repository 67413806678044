<template>
  <pessoa-box class="c-fullHeader processo-box">
    <div class="full-bg">
      <div class="c-pessoa">
        <h1>{{processo.numero | formataNumeroProcesso}}</h1>
        <div class="show-pessoa">
          <div class="m-l-sm">
            <div class="def-box box-full box-blue">
              <span class="font-bold no-select cursor-pointer" @click="acessarTribunal" v-if="isProcessoFisico">Acessar tribunal</span>
              <span v-if="processo.tipo === 1" class="no-border m-l-xs">Processo Eletrônico</span>
              <span v-else class="no-border m-l-xs">Processo Físico</span>
              <!--<i class="fas fa-tag"></i>-->
            </div>
          </div>
        </div>
      </div><!-- Pessoa Ativa-->

      <div class="right">
        <div class="c-user">
          <processo-responsavel ref="clienteComponent" :pessoa="processo.cliente" :processo="processo" type="cliente" label="Cliente" />
        </div><!-- Show User -->
        <div class="c-user">
          <processo-responsavel ref="responsavelComponent" :pessoa="processo.responsavel" :processo="processo" type="responsavel" label="Responsável" />
        </div><!-- Show User -->

        <div class="c-actions">
          <u-btn v-if="processo.tribunal.nome === 'TJMG'" @click="atualizarProcesso" :loading="atualizandoProcesso" no-caps class="btnContato no-border-radius-right"><u-icon name="robot" type="fa" icon-style="light" class="m-r-sm m-l-n-sm" /> Atualizar processo</u-btn>
<!--          <u-btn icon="sort-down" icon-type="fa" no-caps class="simple-btn p-btn-down">
            <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
              <div>
                <ul>
                  <menu-options-item label="Adicionar novo negócio" close  />
                  <menu-options-item label="Adicionar novo processo" close  />
                </ul>
              </div>
            </u-popover>
          </u-btn>-->
          <u-btn icon="ellipsis-h" icon-type="fa" class="btn-action">
            <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
              <div>
                <ul>
                      <menu-options-item @click="windowProcesso(processo.id)" label="Editar cadastro" close  />
                      <menu-options-item v-if="processo.cliente" @click="removerCliente()" label="Remover cliente" close  />
                      <menu-options-item v-if="processo.responsavel" @click="removerResponsavel()" label="Remover responsável" close  />
<!--                  <menu-options-item label="Excluir" close  />--> <!-- @TODO: Excluir. Analisar -->
                </ul>
                <div class="diviser"></div>
                <ul>
                  <menu-options-item disabled label="Gerenciar seções da barra lateral" close  /> <!-- @TODO -->
                </ul>
              </div>
            </u-popover>
          </u-btn>
        </div><!-- END Actions -->
      </div>
    </div>
  </pessoa-box><!-- END Full Header -->
</template>

<script>
import ProcessoMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UPopover} from "uloc-vue"
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import ProcessoResponsavel from "@/components/processos/components/processos/include/Responsavel"
import windowProcesso from "@/components/processos/components/processos/window/windowProcesso"
import {TIPO_PROCESSO_FISICO} from "@/domain/processos/helpers/tipo-processo";
import {callRobo} from "@/domain/processos/services/robo";

export default {
  name: "ProcessoHeader",
  components: {ProcessoResponsavel, PessoaBox, MenuOptionsItem, UPopover},
  mixins: [ProcessoMixin],
  inject: ['appContainer'],
  data () {
    return {
      atualizandoProcesso: false
    }
  },
  computed: {
    isProcessoFisico () {
      return this.processo?.tipo === TIPO_PROCESSO_FISICO
    }
  },
  methods: {
    windowProcesso: windowProcesso,
    removerCliente () {
      this.$refs.clienteComponent.desvincular()
    },
    removerResponsavel () {
      this.$refs.responsavelComponent.desvincular()
    },
    atualizarProcesso () {
      if (this.atualizandoProcesso) return
      this.atualizandoProcesso = true
      this.callRoboProcesso(this.processo)
      .then(() => {
        this.atualizandoProcesso = false
        this.$router.push({name: 'processo', params: {id: this.processo.id}})
        setTimeout(() => {
          this.appContainer.load()
        }, 1000)
      })
      .catch((err) => {
        this.alertApiError(err)
        this.atualizandoProcesso = false
      })
    },
    acessarTribunal () {
      let {numero} = this.processo
      numero = numero.replace(/\D/g, '')
      const codigoComarca = numero.slice(-4)
      // https://www4.tjmg.jus.br/juridico/sf/proc_resultado.jsp?tipoPesquisa=1&txtProcesso=15297603020088130024&comrCodigo=24&nomePessoa=&tipoPessoa=X&naturezaProcesso=0&situacaoParte=X&codigoOAB=&tipoOAB=N&ufOAB=MG&numero=1&select=1&tipoConsulta=1&natureza=0&ativoBaixado=X&listaProcessos=15297603020088130024
      const link = `https://www4.tjmg.jus.br/juridico/sf/proc_resultado.jsp?tipoPesquisa=1&txtProcesso=${numero}&comrCodigo=${+codigoComarca}&nomePessoa=&tipoPessoa=X&naturezaProcesso=0&situacaoParte=X&codigoOAB=&tipoOAB=N&ufOAB=MG&numero=1&select=1&tipoConsulta=1&natureza=0&ativoBaixado=X&listaProcessos=${numero}`
      window.open(link)
    }
  }
}
</script>
