export default {
    props: {
        processo: {
            required: true
        }
    },
    methods: {
        gotoProcesso (p, target = '_blank') {
            let router = this.$router || this.router
            let routeData = router.resolve({name: 'processo', params: {id: p.id}})
            window.open(routeData.href, '_blank')
        }
    }
}
