<template>
  <pessoa-box class="box-detalhes">
    <div class="headerBox">
      <div class="left">
        <strong>Contatos</strong>
      </div>
      <div class="right">
        <!-- @click="editing = !editing" -->
        <u-btn icon="pen" icon-type="fa" @click="editing = !editing" class="simple-icon"
               :class="{'text-green': editing}"/>
        <!--        <u-btn @click="expand = !expand" :icon="expand ? 'unfold_less' : 'unfold_more'" class="simple-icon c-arrows">
                  <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
                    <span v-if="expand">Recolher</span>
                    <span v-else>Expandir</span>
                  </u-tooltip>
                </u-btn>-->
        <!--        <u-btn label="Personalizar campos" no-caps />-->
      </div>
    </div><!---->

    <div class="bodyBox e-input-modern size1" :class="{editing: editing, 'mb1': !editing}">
      <pessoa-detalhe-field v-for="(contato, i) in fake.contatos" :key="'pcontato-' + i" label="Nome" value="pessoa.name">
        <template v-if="editing" v-slot:content>
          <div class="flex justify-between contato-lista no-wrap">
            <div class="m-r-xs">
              <erp-s-field
                  :view="'tl'"
                  :label="'Pessoa'"
                  :helper-position="'lb'"
              >
                <autocomplete :search="searchPerson" v-model="contato.pessoa" icon="user"/>
              </erp-s-field>
            </div>
            <div class="m-r-xs">
              <erp-s-field
                  :view="'tl'"
                  :label="'Tipo do Contato'"
                  :helper-position="'lb'"
              >
                <u-input hide-underline class="app-input full-width" v-model="contato.descricao"/>
              </erp-s-field>
            </div>
            <div class="">
              <erp-s-field
                  :view="'tl'"
                  :label="'Contato'"
                  :helper-position="'lb'"
              >
                <u-input hide-underline class="app-input full-width" v-model="contato.contato"/>
              </erp-s-field>
            </div>
          </div>
        </template>
        <template v-else v-slot:content>
          <div class="flex justify-between contato-lista no-wrap full-width">
            <div class="m-b-sm" style="width: 33%">
              <pessoa-link :pessoa="contato.pessoa" />
            </div>
            <div class="m-b-sm" style="width: 33%">{{contato.descricao}}</div>
            <div class="m-b-sm" style="width: 33%">{{contato.contato}}</div> <!-- @TODO: Caller se for telefone -->
          </div>
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field v-if="!editing && (!processo.contatos || !processo.contatos.length)" label="">
        <template v-slot:content>
          <div class="m-t-sm m-b">
            Nenhum contato relacionada à este processo até o momento
          </div>
        </template>
      </pessoa-detalhe-field>
      <div v-if="editing" class="infoCont">
        <p class="txtLeft"></p>
        <a @click="adicionarContato" class="font-bold">+ Adicionar contato</a>
      </div>

      <div v-if="expand">
        ...
      </div>

      <pessoa-detalhe-field v-if="editing">
        <template v-slot:right>
          <u-btn @click="salvar" label="Salvar alterações" :loading="loading" no-caps
                 class="btnContato btnForm no-border"/>
        </template>
      </pessoa-detalhe-field>
    </div><!---->

    <div v-if="editing" class="footerBox">
      <div class="p">
        <p>Para <strong class="status-contato status-">Remover um contato</strong> apague o nome do mesmo e clique em salvar</p>
      </div>
    </div>
    <br v-if="!editing">
  </pessoa-box>
</template>

<script>
import Mixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UInput} from "uloc-vue"
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
import {list, updatePerson} from "@/domain/pessoa/services"
import mockContato from "@/domain/processos/helpers/mockContato"
import {ErpSField} from "uloc-vue-plugin-erp"
import Autocomplete from "@/components/layout/components/Autocomplete"
import {updateContatoProcesso} from "@/domain/processos/services"
import globalPersonMixin from "@/components/pessoa/globalPersonMixin"
import PessoaLink from "@/components/pessoa/components/include/PessoaLink";
const searchPerson = function (terms) {
  let filtros = encodeURI(`&sortBy=name&descending=false&startWith=${terms}`)
  return list(50, 1, filtros)
}

export default {
  name: "ContatoProcesso",
  mixins: [Mixin, globalPersonMixin],
  components: {
    PessoaLink,
    PessoaDetalheField,
    PessoaBox,
    // UTooltip,
    ErpSField,
    Autocomplete,
    UInput,
    // UCheckbox
    // USelect
  },
  data() {
    return {
      expand: false,
      editing: false,
      loading: false,
      fake: {
        contatos: null
      },
      updatingEmail: false
    }
  },
  mounted() {
    this.mountFakeContatos()
  },
  computed: {
  },
  methods: {
    mountFakeContatos () {
      this.fake.contatos = this.processo.contatos.map(c => {
        return {
          ...c,
          pessoa: {
            ...c.pessoa,
            value: c.pessoa.name,
            label: c.pessoa.name
          }
        }
      })
    },
    salvar() {
      this.loading = true
      let processo = JSON.parse(JSON.stringify(this.processo))
      let contatos = JSON.parse(JSON.stringify(this.fake.contatos))
      contatos = contatos.map(p => {
        return {
          ...p,
          tipo: p.tipoId
        }
      })
      processo.contatos = contatos
      updateContatoProcesso(processo)
          .then(response => {
            this.loading = false
            this.editing = false
            this.$emit('update', response.data)
            this.$uloc.notify({
              color: 'positive',
              message: `Processo atualizado com sucesso.`,
              position: 'bottom-left',
            })
            this.$nextTick(() => {
              this.mountFakeContatos()
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    adicionarContato() {
      this.fake.contatos.push(JSON.parse(JSON.stringify(mockContato)))
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova pessoa`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    }
  }
}
</script>
