import AppContatos from "@/components/pessoa/components/include/pessoa/apps/Contatos"
import AppObservacoes from "@/components/pessoa/components/include/pessoa/apps/Observacoes"
import AppAtividade from "@/components/pessoa/components/include/pessoa/apps/Atividade"
import Ligar from "@/components/processos/components/processos/include/apps/Ligar"
import Arquivos from "@/components/pessoa/components/include/pessoa/apps/Arquivos"
import Comunicacao from "@/components/pessoa/components/include/pessoa/apps/Comunicacao"
import {criarNota, uploadArquivoProcesso} from "@/domain/processos/services"

export const processoApps = {
    observacoes: {
        name: 'observacoes',
        component: AppObservacoes,
        label: 'Observações',
        icon: 'far fa-list-alt',
        methods: {
            criarNota: criarNota
        }
    },
    contatos: {
        name: 'contatos',
        component: AppContatos,
        label: 'Contatos',
        icon: 'far fa-address-book',
        methods: {
            criarNota: criarNota
        }
    },
    atividade: {
        name: 'atividade',
        component: AppAtividade,
        label: 'Tarefas',
        icon: 'far fa-calendar-day'
    },
    ligar: {
        name: 'ligar',
        component: Ligar,
        label: 'Ligar',
        icon: 'fas fa-phone fa-rotate-90'
    },
    email: {
        name: 'email',
        component: Comunicacao,
        label: 'E-mail',
        icon: 'far fa-envelope'
    },
    arquivos: {
        name: 'arquivos',
        component: Arquivos,
        label: 'Arquivos',
        icon: 'far fa-paperclip',
        methods: {
            uploadArquivo: uploadArquivoProcesso
        }
    }
}
