<template>
  <div class="mod-pessoa mod-processo" v-if="!loading">
    <processo-header ref="header" :processo="processo" />
    <processo-menu :active="app" @changeApp="changeApp" />
    <div v-if="app === 'principal'" class="cont-allBox">
      <div class="f-left">
        <processo-detalhes @update="(p) => processo = p" :processo="processo" />
        <processo-partes @update="(p) => processo = p" :processo="processo" />
        <negocio-detalhes @update="(p) => processo = p" :processo="processo" />
        <contato-processo @update="(p) => processo = p" :processo="processo" />
      </div><!-- Flex Left -->

      <div class="f-right">
        <processo-abas :processo="processo" />

        <processo-historico :processo="processo" />
      </div>
    </div>
    <component v-else v-bind:is="currentTabComponent" :processo="processo" :class="processoApps2[currentTabComponent].class" :parse-props="parseProps(processoApps2[currentTabComponent])"></component>
  </div>
  <div class="flex flex-center h-full" v-else>
    <sl-loading />
  </div>
</template>

<script>
/**
 * @TODO: <RAPHAEL> Poder excluir uma nota
 * @TODO: <RAPHAEL> Poder fixar uma nota
 * @TODO: <V2> Poder excluir um log (Somente administrador)
 * @TODO: <V2> Mostrar log de Planejamento no histórico e torná-lo funcional
 */
import {downloadArquivoProcesso, find, criarNota, showNota, deleteNota, deleteArquivo} from '@/domain/processos/services'
import SlLoading from "@/components/layout/components/Loading"
import ProcessoHeader from "./include/Header"
import ProcessoDetalhes from "@/components/processos/components/processos/include/Detalhes"
import ProcessoPartes from "@/components/processos/components/processos/include/DetalhesPartes"
import NegocioDetalhes from "@/components/processos/components/processos/include/Negocio"
import ContatoProcesso from "@/components/processos/components/processos/include/Contatos"
import ProcessoAbas from "@/components/processos/components/processos/include/ProcessoAbas"
import ProcessoHistorico from "@/components/processos/components/processos/include/Historico"
import ProcessoMenu from "@/components/processos/components/processos/include/Menu"
import {processoApps2} from "@/components/processos/components/processos/include/apps/appList2"
import Vue from "vue"
import {formataNumeroProcesso} from "@/utils/processo-utils"

for (let app in processoApps2) {
  Vue.component(processoApps2[app].name, processoApps2[app].component)
}

export default {
  provide: function () {
    return {
      appContainer: this
    }
  },
  name: "Pessoa",
  components: {
    ProcessoMenu,
    ProcessoHistorico,
    ProcessoAbas,
    ContatoProcesso,
    NegocioDetalhes,
    ProcessoPartes,
    ProcessoDetalhes,
    ProcessoHeader,
    SlLoading
  },
  data () {
    return {
      processo: null,
      loading: true,
      app: 'principal',
      processoApps2
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  computed: {
    me () {
      return this.$uloc.auth.session.user.person
    },
    intercept () {
      return {
        criarNota: (data) => {
          return criarNota(this.processo, data)
        },
        showNota: (data) => {
          return showNota(this.processo, data)
        },
        deleteNota: (data) => {
          return deleteNota(this.processo, data)
        },
        downloadArquivo: (a, b) => {
          return downloadArquivoProcesso(this.processo, a, b)
        },
        deleteArquivo: (data) => {
          return deleteArquivo(this.processo, data)
        },
      }
    },
    currentTabComponent: function () {
      return this.app
    }
  },
  methods: {
    load(id) {
      if (typeof id === 'undefined') {
        id = this.$route.params.id
      }
      this.loading = true
      find(id)
          .then(({data}) => {
            this.loading = false
            this.processo = data
            if (document && document.title) {
              document.title = 'Processo ' + formataNumeroProcesso(this.processo.numero)
            }
            this.processo.negocio = this.processo.negocio?.id
            console.log(data)
            this.$nextTick(() => {
              if (this.$route.query.new && this.$appConfig.isConfigNotEmpty('processos.apiPje')) {
                this.$refs.header.atualizarProcesso()
              }
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    atualizaProdutividade (produtividade) {
      this.processo.produtividade = produtividade
    },
    changeApp (name) {
      console.log('Change app to ', name)
      this.app = name
    },
    parseProps (app) {
      const props = {}
      if (app.parseProps) {
        Object.assign(props, app.parseProps)
      }
      props.module = 'processos'
      props.processo = this.processo
      props.relationEntity = 'processo'
      props.relationEntityId = this.processo.id
      return props
    }
  }
}
</script>
