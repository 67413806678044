<template>
  <a v-if="pessoa">
    <div @click="gotoPerson(pessoa)" class="cont-avatar">
      <img v-if="pessoa.photo" :src="pessoa.photo">
      <div v-else class="img-fake">{{ firstLettersName(pessoa.name) }}</div>
    </div>
    <div class="show-user">
      <strong @click="gotoPerson(pessoa)">{{ firstSecondName(pessoa.name) }}</strong>
      <span @click="gotoPerson(pessoa)">{{ label }}</span>
      <!--              <i class="fas fa-sort-down"></i>-->
      <u-btn @click="$refs.popover.toggle()" title="Transferir propriedade" icon="sort-down" icon-type="fa" size="xs" class="simple-btn no-border" flat
             outline dense color="white">
        <popover ref="popover" :processo="processo" :type="type" />
      </u-btn>
    </div>
  </a>
  <a @click="$refs.popover.toggle()" v-else>
    <div class="show-user">
      <strong>Nenhum {{ label }}</strong>
      <span>Clique para adicionar</span>
    </div>
    <popover ref="popover" :processo="processo" :type="type" />
  </a>
</template>

<script>
import Mixin from './mixin'
import Popover from "@/components/processos/components/processos/include/PopoverResponsavel"

export default {
  components: {Popover},
  mixins: [Mixin],
  name: "ProcessoResponsavel",
  props: ['pessoa', 'type', 'label'],
  methods: {
    desvincular () {
      this.$refs.popover.desvincular()
    },
    firstLettersName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0)
      }
      return String(name).substr(0, 2)
    },
    firstSecondName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0] + ' ' + name[1]
      }
      return String(name).substr(0, 10)
    },
    gotoPerson (p, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
