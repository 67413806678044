<template>
  <pessoa-box class="box-detalhes">
    <div class="headerBox">
      <div class="left">
        <strong>Partes</strong>
      </div>
      <div class="right">
        <!-- @click="editing = !editing" -->
        <u-btn icon="pen" icon-type="fa" @click="editing = !editing" class="simple-icon"
               :class="{'text-green': editing}"/>
<!--        <u-btn @click="expand = !expand" :icon="expand ? 'unfold_less' : 'unfold_more'" class="simple-icon c-arrows">
          <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
            <span v-if="expand">Recolher</span>
            <span v-else>Expandir</span>
          </u-tooltip>
        </u-btn>-->
        <!--        <u-btn label="Personalizar campos" no-caps />-->
      </div>
    </div><!---->

    <div class="bodyBox e-input-modern size1" :class="{editing: editing, 'mb1': !editing}">
      <pessoa-detalhe-field v-for="(parte, i) in fake.partes" :key="'parte-' + i" label="Nome" value="pessoa.name">
        <template v-if="editing" v-slot:content>
          <p class="txtLeft">
            <tipo-parte-select label="Tipo" v-model="parte.tipoId" />
          </p>
          <p class="txtRight pos-rlt">
            <erp-s-field
                :view="'tl'"
                :label="'Pessoa'"
                :helper-position="'lb'"
            >
              <autocomplete :search="searchPerson" v-model="parte.pessoa" icon="user"/>
            </erp-s-field>
          </p>
        </template>
        <template v-else v-slot:content>
          <p class="txtLeft">
            {{ parte.tipo ? parte.tipo.nome : 'Parte' }}
          </p>
          <p class="txtRight">
            <pessoa-link :pessoa="parte.pessoa" />
          </p>
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field v-if="!editing && (!processo.partes || !processo.partes.length)" label="">
        <template v-slot:content>
          <div class="m-t-sm m-b">
          Nenhuma parte relacionada à este processo até o momento
          </div>
        </template>
      </pessoa-detalhe-field>
      <div v-if="editing" class="infoCont">
        <p class="txtLeft"></p>
        <a @click="adicionarParte" class="font-bold">+ Adicionar parte</a>
      </div>

      <div v-if="expand">
        ...
      </div>

      <pessoa-detalhe-field v-if="editing">
        <template v-slot:right>
          <u-btn @click="salvar" label="Salvar alterações" :loading="loading" no-caps
                 class="btnContato btnForm no-border"/>
        </template>
      </pessoa-detalhe-field>
    </div><!---->

    <div v-if="editing" class="footerBox">
      <div class="p">
        <p>Para <strong class="status-contato status-">Remover uma parte</strong> apague o nome da mesma e clique em salvar</p>
      </div>
    </div>
    <br v-if="!editing">
  </pessoa-box>
</template>

<script>
import Mixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
// import {UTooltip, UInput, UCheckbox, USelect} from "uloc-vue"
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
import {list, updatePerson} from "@/domain/pessoa/services"
import mockParte from "@/domain/processos/helpers/mockParte"
import TipoParteSelect from "@/components/processos/components/processos/include/TipoParteSelect"
import {ErpSField} from "uloc-vue-plugin-erp"
import Autocomplete from "@/components/layout/components/Autocomplete"
import {updatePartesProcesso} from "@/domain/processos/services"
import globalPersonMixin from "@/components/pessoa/globalPersonMixin"
import PessoaLink from "@/components/pessoa/components/include/PessoaLink";
const searchPerson = function (terms) {
  let filtros = encodeURI(`&sortBy=name&descending=false&startWith=${terms}`)
  return list(50, 1, filtros)
}

export default {
  name: "ProcessoPartes",
  mixins: [Mixin, globalPersonMixin],
  components: {
    PessoaLink,
    TipoParteSelect,
    PessoaDetalheField,
    PessoaBox,
    // UTooltip,
    ErpSField,
    Autocomplete
    // UInput,
    // UCheckbox
    // USelect
  },
  data() {
    return {
      expand: false,
      editing: false,
      loading: false,
      fake: {
        partes: null
      },
      updatingEmail: false
    }
  },
  mounted() {
    this.mountFakePartes()
  },
  computed: {
  },
  methods: {
    mountFakePartes () {
      this.fake.partes = this.processo.partes.map(p => {
        return {
          ...p,
          tipoId: p.tipo && p.tipo.id,
          pessoa: {
            ...p.pessoa,
            value: p.pessoa.name,
            label: p.pessoa.name
          }
        }
      })
    },
    salvar() {
      this.loading = true
      let processo = JSON.parse(JSON.stringify(this.processo))
      let partes = JSON.parse(JSON.stringify(this.fake.partes))
      partes = partes.map(p => {
        return {
          ...p,
          tipo: p.tipoId
        }
      })
      processo.partes = partes
      updatePartesProcesso(processo)
          .then(response => {
            this.loading = false
            this.editing = false
            this.$emit('update', response.data)
            this.$uloc.notify({
              color: 'positive',
              message: `Processo atualizado com sucesso.`,
              position: 'bottom-left',
            })
            this.$nextTick(() => {
              this.mountFakePartes()
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    adicionarParte() {
      this.fake.partes.push(JSON.parse(JSON.stringify(mockParte)))
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova pessoa`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    }
  }
}
</script>
