<template>
  <a class="pessoa-link" @click="gotoPerson(pessoa)" v-if="pessoa && pessoa.id">{{ pessoa.name }}</a>
  <span v-else>{{pessoa ? pessoa.name : '-'}}</span>
</template>

<script>
import globalPersonMixin from "@/components/pessoa/globalPersonMixin"

export default {
  name: "PessoaLink",
  props: ['pessoa'],
  mixins: [globalPersonMixin]
}
</script>
