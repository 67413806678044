<template>
  <pessoa-box class="box-detalhes">
    <div class="headerBox">
      <div class="left">
        <strong>Negócio</strong>
      </div>
      <div class="right">
        <!-- @click="editing = !editing" -->
        <!--        <u-btn icon="pen" icon-type="fa" @click="windowProcesso(processo.id)" class="simple-icon"
                       :class="{'text-green': editing}"/>-->
        <u-btn @click="expand = !expand" :icon="expand ? 'unfold_less' : 'unfold_more'" class="simple-icon c-arrows">
          <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
            <span v-if="expand">Recolher</span>
            <span v-else>Expandir</span>
          </u-tooltip>
        </u-btn>
        <!--        <u-btn label="Personalizar campos" no-caps />-->
      </div>
    </div><!---->

    <div class="bodyBox e-input-modern size1" :class="{editing: editing, 'mb1': !editing}">
      <pessoa-detalhe-field label="Valor da Causa" :value="'R$ ' + $options.filters.moeda(processo.valorCausa)">
        <template v-if="editing" v-slot:right>
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Produtividade" value="">
        <template v-slot:right>
          <div class="flex no-wrap items-center" style="margin-left: -3px !important;">
            <div class="flex-zero m-r-xs">
              <label data-test="stage-1-rotting-flag" class="check-switch">
                <input type="checkbox" @click.stop.prevent="alterarProdutividade" v-model="fake.ativarProdutividade">
                <div class="check-switch-toggle"></div>
              </label>
            </div>
            <span class="col-grow-1 font-12" v-if="fake.ativarProdutividade">Habilitado</span>
            <span class="col-grow-1 font-12" v-else>Desabilitado</span>
          </div>
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field class="m-t" label="">
        <template v-slot:right>
          <u-btn
              :label="processo.negocio ? 'Ver negócio' : 'Criar negócio'"
              no-caps
              class="btnContato btnForm no-border"
              @click="processaNegocio"
              :loading="loading"
          />
          <u-btn v-if="processo.produtividade" label="Produtividade" no-caps
                 class="btnContato btnForm no-border m-l-sm green2"
                 @click="$router.push({name: 'produtividade.main', query: {id: processo.produtividade.id}})"
          />
          <div class="m-b-lg"></div>
        </template>
      </pessoa-detalhe-field>

      <div v-if="expand">

      </div>

      <pessoa-detalhe-field v-if="editing">
        <template v-slot:right>
          <u-btn @click="salvar" label="Salvar alterações" :loading="loading" no-caps
                 class="btnContato btnForm no-border"/>
        </template>
      </pessoa-detalhe-field>
    </div><!---->

    <!--<div class="footerBox">
      <div class="p">
        <p>Informação de Dados de Contato Inteligentes <strong class="status-contato status-">Avançado</strong></p>
        <a href="">Montrar mais</a>
      </div>
    </div>--><!---->
  </pessoa-box>
</template>

<script>
import Mixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UTooltip, UInput, UCheckbox, USelect} from "uloc-vue"
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
import {stopAndPrevent} from "uloc-vue/src/utils/event"
import {habilitarProdutividadeProcesso} from "@/domain/processos/services/produtividade"
import {criaNegocio} from "@/domain/processos/services/negocio";

export default {
  name: "NegocioDetalhes",
  inject: ['appContainer'],
  mixins: [Mixin],
  components: {
    PessoaDetalheField,
    PessoaBox,
    UTooltip,
    // UInput,
    // UCheckbox
    // USelect
  },
  data() {
    return {
      expand: false,
      editing: false,
      loading: false,
      fake: {
        ativarProdutividade: this.processo && this.processo.produtividade
      }
    }
  },
  mounted() {
  },
  watch: {
    'processo.produtividade' (v) {
      if (v) {
        this.fake.ativarProdutividade = true
        return
      }
      this.fake.ativarProdutividade = false
    }
  },
  computed: {},
  methods: {
    salvar() {
      this.loading = true
      /*let pessoa = this.transformToSubmit(this.pessoa)
      updatePerson(pessoa)
          .then(response => {
            this.$emit('updatePerson', response.data)
            this.loading = false
            this.$uloc.notify({
              color: 'positive',
              message: `Cadastro atualizado com sucesso!`,
              position: 'bottom-left',
            })
            this.editing = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })*/
    },
    alterarProdutividade(e) {
      stopAndPrevent(e)
      console.log(e.target.value)
      if (e.target.value) {
        this.$uloc.dialog({
          title: 'Ativar produtividade para o Processo',
          message: `Tem certeza que deseja ativar a produtividade para este processo?`,
          noCaps: true,
          ok: `Sim, ativar`,
          cancel: 'Não'
        })
            .then(() => {
              this.$uloc.loading.show()
              habilitarProdutividadeProcesso(this.processo.id)
                  .then(response => {
                    this.appContainer.atualizaProdutividade(response.data)
                    this.fake.ativarProdutividade = true
                    this.$uloc.loading.hide()
                  })
                  .catch(error => {
                    this.$uloc.loading.hide()
                    this.alertApiError(error)
                  })
            })
            .catch(() => {
              this.fake.ativarProdutividade = false
            })
      }
    },
    processaNegocio () {
      const {negocio} = this.processo
      if (negocio) {
        this.visualizaNegocio(this.processo.id)
      } else {
        this.criaNegocio(this.processo.id)
      }
    },
    criaNegocio (processoId) {
      this.loading = true
      criaNegocio(processoId)
          .then(response => {
            this.processo.negocio = response.data.negocio.id
            this.$uloc.notify({
              message: 'Negócio criado!',
              type: 'positive'
            })
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    visualizaNegocio (id) {
      // TODO
    }
  }
}
</script>
