<template>
  <div class="infoCont">
    <slot name="content">
    <p class="txtLeft">{{ label }}</p>
    <slot name="right">
    <p class="txtRight">
      <slot>
      <span>{{ value }}</span>
      </slot>
    </p>
    </slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: "PessoaDetalheField",
  props: ['label', 'value']
}
</script>
