import http from '../../../utils/services/http'

export const criaNegocio = (idProcesso) => {
    let url = `/api/processos/${idProcesso}/cria-negocio`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}