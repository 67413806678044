<template>
  <pessoa-box class="box-actions-ativo"
              @dragenter.native.prevent.stop="app = 'arquivos'"
              @dragover.native.prevent.stop="app = 'arquivos'">
    <pessoa-aba-header :active="app" @changeApp="changeApp" />
    <!---->

    <div class="cont-bodyAll">

      <component v-bind:is="currentTabComponent" :parse-props="parseProps" :app-methods="tabMethods" class="ativo"></component>
      <div class="itemAba"></div><!-- END Observações -->
      <div class="itemAba"></div><!-- END Atividade -->
      <div class="itemAba"></div><!-- END Sugerir horários -->

      <div class="itemAba">
        <!---->
      </div>

      <div class="itemAba"></div><!-- END E-mail -->
      <div class="itemAba"></div><!-- END Arquivos -->
    </div>
  </pessoa-box>
</template>

<script>
import Vue from 'vue'
import Mixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import PessoaAbaHeader from "@/components/processos/components/processos/include/AbaHeader"
import {processoApps} from "@/components/processos/components/processos/include/apps/appList"


for (let app in processoApps) {
  Vue.component(processoApps[app].name, processoApps[app].component)
}
// Vue.component(processoApps.observacoes.name, processoApps.observacoes.component)
// Vue.component(processoApps.atividade.name, processoApps.atividade.component)

export default {
  name: "ProcessoAbas",
  mixins: [Mixin],
  components: {
    PessoaAbaHeader,
    PessoaBox
  },
  data () {
    return {
      app: processoApps.observacoes.name,
      parseProps: {
        module: 'processo',
        pessoa: this.processo, // Fake
        autoSelect: {
          cliente: this.processo.cliente,
          processo: this.processo,
        },
        processo: this.processo,
        contatos: []
        // entidade: 'processo',
        // entidadeId: this.processo.id
      }
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.app
    },
    tabMethods () {
      if (typeof processoApps[this.currentTabComponent] !== 'undefined') {
        return processoApps[this.currentTabComponent].methods
      }
      return null
    },
    processoContatos () {
      let contatos = []
      if (this.processo.cliente) {
        const cliente = this.processo.cliente
        cliente && !contatos.find((p) => p.id === cliente.id) && contatos.push({
          ...cliente,
          default: true
        })
      }
      this.processo.contatos && this.processo.contatos.map(c => {
        c.pessoa && !contatos.find((p) => p.id === c.pessoa.id) && contatos.push(c.pessoa)
      })
      this.processo.partes && this.processo.partes.map(c => {
        c.pessoa && !contatos.find((p) => p.id === c.pessoa.id) && contatos.push(c.pessoa)
      })
      return contatos
    }
  },
  methods: {
    changeApp (name) {
      this.parseProps.contatos = []
      if (name === 'contatos') {
        this.parseProps.contatos = this.processoContatos
      }
      console.log('Change app to ', name)
      this.app = name
    }
  }
}
</script>
