<template>
<pessoa-aba>
  <div class="wrapper m-t-n m-b">
    <!--
    :relacao-entidade="parseProps.entidade"
    :relacao-entidade-id="parseProps.entidadeId"
    -->
    <lista-tarefas
        ref="tasks"
        :cliente="parseProps.cliente"
        :processo="parseProps.processo"
        :auto-select="parseProps.autoSelect"
        layout="empty"
        hide-calendar
        hide-kanban
    >
      <template v-slot:bottom>
        <div class="noRowsTemplate">
          <div class="image"><img src="@/components/sltarefas/assets/images/task-icon.png"></div>
          <h2>Atividades relacionadas</h2>
          <p>Aqui você cadastra todas as tarefas relacionadas com prazo e detalhes das atividades.</p>
          <div class="norows-btn m-t">
            <u-btn @click="$refs.tasks.novo()" label="Adicionar uma nova tarefa" color="positive" no-caps icon="plus" icon-type="fa" />
          </div>
        </div>
      </template>
    </lista-tarefas>
  </div>
</pessoa-aba>
</template>

<script>
import PessoaAba from "@/components/pessoa/components/include/pessoa/apps/Aba"
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import ListaTarefas from "@/components/sltarefas/components/ListaTarefas"
export default {
  name: "PessoaAtividade",
  components: {
    PessoaAba,
    ListaTarefas
  },
  mixins: [AppMixin]
}
</script>
