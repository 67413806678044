<template>
  <pessoa-box class="box-detalhes">
    <div class="headerBox">
      <div class="left">
        <strong>Detalhes</strong>
      </div>
      <div class="right">
        <!-- @click="editing = !editing" -->
        <u-btn icon="pen" icon-type="fa" @click="windowProcesso(processo.id)" class="simple-icon"
               :class="{'text-green': editing}"/>
        <u-btn @click="expand = !expand" :icon="expand ? 'unfold_less' : 'unfold_more'" class="simple-icon c-arrows">
          <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
            <span v-if="expand">Recolher</span>
            <span v-else>Expandir</span>
          </u-tooltip>
        </u-btn>
        <!--        <u-btn label="Personalizar campos" no-caps />-->
      </div>
    </div><!---->

    <div class="bodyBox e-input-modern size1" :class="{editing: editing, 'mb1': !editing}">
      <pessoa-detalhe-field label="Tribunal" :value="processo.tribunal ? processo.tribunal.nome : '-'">
        <template v-if="editing" v-slot:right>
          <tribunal-select no-label v-model="fake.tribunal" />
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Número" :value="formataNumeroProcesso(processo.numero)">
        <template v-if="editing" v-slot:right>
          <u-input hide-underline class="app-input full-width" v-model="processo.numero"/>
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Comarca" :value="processo.vara ? processo.vara.comarca.nome : '-'">
        <template v-if="editing" v-slot:right>
          <comarca-select :tribunal="fake.tribunal" no-label v-model="fake.comarca" :limit="10000" />
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Vara" :value="processo.vara ? processo.vara.nome : '-'">
        <template v-if="editing" v-slot:right>
          <vara-select :comarca="fake.comarca" no-label v-model="fake.vara" />
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Data de Cadastro" :value="processo.createdAt ? $options.filters.formatDate(processo.createdAt, 'dd/MM/yyyy HH:mm:ss') : '-'">
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Criado Por" :value="processo.createdByName || '-'">
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Última Alteração" :value="processo.dateModified ? $options.filters.formatDate(processo.dateModified, 'dd/MM/yyyy HH:mm:ss') : '-'">
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Última Movimentação" :value="processo.ultimaMovimentacao ? $options.filters.formatDate(processo.ultimaMovimentacao, 'dd/MM/yyyy HH:mm:ss') : '-'">
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Situação" :value="processo.status ? processo.status.nome : '-'">
        <template v-if="editing" v-slot:right>
          <status-processo-select no-label v-model="fake.status" />
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Classificação" :value="processo.classificacao ? processo.classificacao.nome : '-'">
        <template v-if="editing" v-slot:right>
          <classificacao-processo-select no-label :status="fake.status" v-model="fake.classificacao" />
        </template>
      </pessoa-detalhe-field>

      <pessoa-detalhe-field label="Acompanhamento" value="">
        <template v-slot:right>
          <div class="flex no-wrap items-center" style="margin-left: -3px !important;">
            <div class="flex-zero m-r-xs">
              <u-checkbox class="app-input full-width" v-model="processo.ativarRobo"/>
            </div>
            <span class="col-grow-1 font-12">Ativar robôs para varredura (push)</span>
          </div>
        </template>
      </pessoa-detalhe-field>

      <div v-if="expand">

      </div>

      <pessoa-detalhe-field v-if="editing">
        <template v-slot:right>
          <u-btn @click="salvar" label="Salvar alterações" :loading="loading" no-caps
                 class="btnContato btnForm no-border"/>
        </template>
      </pessoa-detalhe-field>
    </div><!---->

    <!--<div class="footerBox">
      <div class="p">
        <p>Informação de Dados de Contato Inteligentes <strong class="status-contato status-">Avançado</strong></p>
        <a href="">Montrar mais</a>
      </div>
    </div>--><!---->
  </pessoa-box>
</template>

<script>
import Mixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UTooltip, UInput, UCheckbox, USelect} from "uloc-vue"
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
// import {Tipo} from "@/domain/pessoa/helpers/Status"
import {updatePerson} from "@/domain/pessoa/services"
import TribunalSelect from "@/components/processos/components/processos/include/TribunalSelect"
import StatusProcessoSelect from "@/components/processos/components/processos/include/StatusProcessoSelect";
import ClassificacaoProcessoSelect
  from "@/components/processos/components/processos/include/ClassificacaoProcessoSelect"
import VaraSelect from "@/components/processos/components/processos/include/VaraSelect"
import ComarcaSelect from "@/components/processos/components/processos/include/ComarcaSelect"
import windowProcesso from "@/components/processos/components/processos/window/windowProcesso"
import {updateProcesso} from "@/domain/processos/services";
import {formataNumeroProcesso} from "../../../../../utils/processo-utils";

export default {
  name: "ProcessoDetalhes",
  mixins: [Mixin],
  components: {
    ComarcaSelect,
    VaraSelect,
    ClassificacaoProcessoSelect,
    StatusProcessoSelect,
    TribunalSelect,
    PessoaDetalheField,
    PessoaBox,
    UTooltip,
    UInput,
    UCheckbox
    // USelect
  },
  data() {
    return {
      expand: false,
      editing: false,
      loading: false,
      fake: {
        tribunal: this.processo.tribunal && this.processo.tribunal.id,
        comarca: this.processo.vara && this.processo.vara.comarca ? this.processo.vara.comarca : null,
        vara: this.processo.vara && this.processo.vara.id,
        status: this.processo.status && this.processo.status.id,
        classificacao: this.processo.classificacao && this.processo.classificacao.id,
      },
      updatingEmail: false
    }
  },
  mounted() {
  },
  computed: {
  },
  watch: {
    'processo.ativarRobo' (v) {
      updateProcesso(this.processo.id, {
        ativarRobo: v
      }, 'patch')
          .then(response => {
            if (!v) return
            const {ativarRobo} = response.data
            if (ativarRobo) return
            this.$uloc.notify({
              color: 'danger',
              message: 'A integração com o serviço de robôs não está ativada. Contate o suporte'
            })
            this.processo.ativarRobo = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  methods: {
    windowProcesso: windowProcesso,
    salvar() {
      this.loading = true
      let pessoa = this.transformToSubmit(this.pessoa)
      pessoa.birthDate = this.fake.birthDate
      updatePerson(pessoa)
          .then(response => {
            this.$emit('updatePerson', response.data)
            this.loading = false
            this.$uloc.notify({
              color: 'positive',
              message: `Cadastro atualizado com sucesso!`,
              position: 'bottom-left',
            })
            this.editing = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    formataNumeroProcesso
  }
}
</script>
